import axios from "axios"
import router from "@/router";

const instance = axios.create({
  baseURL: "/api",
  // baseURL: "https://www.worldintek.com:8081/home",
  // baseURL: "http://10.0.0.226:18181/home",
  timeout: 5000
})

function redirectErr(status){
  if (status === 404) {
    router.replace({path: '/404'}).then(r => {
      return null;
    })
  } else if (status === 500) {
    router.replace({path: '/500'}).then(r => {
      return null;
    })
  }
}

instance.interceptors.request.use(config => {
  return config
}, error => {
  console.log(error);
})

instance.interceptors.response.use(result => {
  let payload = result.data;
  redirectErr(payload.status)
  return payload.data
}, error => {
  redirectErr(error.response.status)
})

export function request(config){
  return instance(config)
}
